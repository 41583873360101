import React from 'react';
import Header from './components/Header';
import Particles from 'react-particles-js';
import logo from '../../assets/images/belka_winter.png';
import { links } from '../index/components/constants';
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Col, Drawer, Input, Row, Spin, Timeline } from 'antd';
import { LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import useDispatch from '../../utils/hooks/useDispatch';
import { TrackingItem } from '../../redux/tracking/types';
import { loadTracking } from '../../redux/tracking/action-creators';
import moment from 'moment';
import Footer from '../index/components/Footer';

const renderDate = (date: string) => {
  const _date = moment(date);
  if (_date.isValid()) return _date.format('DD MMMM YYYY HH:mm');
  return '';
};


function TrackingPage() {

  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [tracking, setTracking] = React.useState<TrackingItem[]>([]);
  const [inited, setInited] = React.useState(false);
  const [number, setNumber] = React.useState<string>();
  const [notFound, setNotFound] = React.useState(false);

  const handleLoad = React.useCallback(
      () => {
        setLoading(true);
        setError(null);
        setInited(true);
        setNotFound(false);
        dispatch(loadTracking(number || '')).then(
            (tracking) => setTracking(tracking),
            (errors) => {
              if (errors.trackingNumber) setError(errors.trackingNumber);
              else setNotFound(true);
            }
        ).finally(() => setLoading(false));
      },
      [dispatch, number],
  );


  const particleCount = React.useMemo(() => {
    const dpiContainer = document.getElementById('dpi');
    if (dpiContainer) {
      const width = window.outerWidth / dpiContainer.offsetWidth;
      console.debug('width', width, dpiContainer)
      return Math.ceil(width / 3);
    }
    return 25;
  }, []);

  return (
      <div className="pls-tracking-page">
        <div className="pls-header">
          <Particles
              className="pls-header-bg"
              params={{
                'interactivity': {
                  'events': {
                    'onClick': {
                      'mode': 'push'
                    },
                    'onDiv': {
                      'selectors': '#repulse-div',
                      'mode': 'repulse'
                    },
                    'onHover': {
                      'enable': true,
                      'mode': 'repulse',
                      'parallax': {
                        'force': 60
                      }
                    }
                  },
                  'modes': {
                    'bubble': {
                      'distance': 600,
                      'duration': 2,
                      'opacity': 0.8,
                      'size': 40
                    },
                    'grab': {
                      'distance': 400
                    },
                    'slow': {
                      'factor': 1,
                      'radius': 0
                    }
                  }
                },
                'particles': {
                  'number': {
                    'density': {
                      'enable': true
                    },
                    'value': particleCount
                  },
                  'line_linked': {
                    'enable': false
                  },
                  collisions: {
                    enable: true,
                    mode: 'bounce',
                    overlap: {
                      enable: false,
                      retries: 100,
                    },
                    bounce: {
                      horizontal: {
                        random: {
                          enable: true,
                          minimumValue: 0.1,
                        },
                        value: 1,
                      },
                      vertical: {
                        random: {
                          enable: true,
                          minimumValue: 0.1,
                        },
                        value: 1,
                      },
                    }
                  },
                  // 'links': {
                  //   'color': {
                  //     'value': '#ffffff'
                  //   },
                  //   'distance': 150,
                  //   'enable': true,
                  //   'opacity': 0.4,
                  //   'shadow': {
                  //     'color': {
                  //       'value': 'lime'
                  //     }
                  //   }
                  // },
                  'move': {
                    'speed': 0.3,
                    outModes: {
                      default: 'bounce',
                      bottom: 'bounce',
                      left: 'bounce',
                      right: 'bounce',
                      top: 'bounce'
                    },
                    random: true,
                  },
                  'shape': {
                    'type': [
                      'image',
                    ],
                    'image': [
                      {
                        'src': '/images/bg1.svg',
                        'height': 195,
                        'width': 157
                      },
                      {
                        'src': '/images/bg2.svg',
                        'height': 242,
                        'width': 193
                      },
                      {
                        'src': '/images/bg3.svg',
                        'height': 197,
                        'width': 159
                      },
                      {
                        'src': '/images/bg4.svg',
                        'height': 244,
                        'width': 196
                      },
                      {
                        'src': '/images/bg5.svg',
                        'height': 212,
                        'width': 264
                      },
                      {
                        'src': '/images/bg6.svg',
                        'height': 222,
                        'width': 176
                      }
                    ]
                  },
                  'color': {
                    'value': '#CCC'
                  },
                  'opacity': {
                    'value': {
                      'min': 0.1,
                      'max': 0.5
                    },
                    'animation': {
                      'enable': true,
                      'speed': 0.3,
                      'minimumValue': 0.1
                    }
                  },
                  'size': {
                    'value': 60,
                    'random': false,
                    // 'anim': {
                    //   'enable': true,
                    //   'speed': 4,
                    //   'size_min': 10,
                    //   'sync': false
                    // }
                  }
                },
                'retina_detect': false
              }}
          />
          <div className="pls-header-content">
            <div className="pls-header-navigation">
              <Link to="/">
                <img src={logo} alt="logo" width={184}
                     className="pls-header-logo pls-z-index-10 pls-relative pls-pointer"/>
              </Link>
              <div className="pls-header-navigation-links">
                {links.map(({ title, to, external }) => (
                    external ? <a target="_blank" rel="noreferrer"
                                  className="pls-text-white pls-font-weight-600 pls-z-index-10 pls-relative"
                                  href={to}>{title}</a> :
                        <Link smooth className="pls-text-white pls-font-weight-600 pls-z-index-10 pls-relative"
                              to={to}>{title}</Link>))}
              </div>
              <a href="https://lk.belkapost.ru" target="_blank" className="pls-z-index-10">
                <Button className="pls-button pls-button-rounded pls-button-white">
                  Личный кабинет
                </Button>
              </a>

              {/*<Button className="pls-z-index-10" onClick={() => setDrawerOpen(!drawerOpen)}>*/}
              {drawerOpen ? <MenuFoldOutlined className="pls-z-index-10 pls-d-lg-none"
                                              onClick={() => setDrawerOpen(!drawerOpen)}/> :
                  <MenuUnfoldOutlined onClick={() => setDrawerOpen(!drawerOpen)}
                                      className="pls-z-index-10 pls-d-lg-none"/>}
              {/*</Button>*/}

              <Drawer className="pls-header-drawer" closable={false} onClose={() => setDrawerOpen(false)}
                      visible={drawerOpen}>
                <div className="pls-d-flex pls-flex-column pls-h-100">
                  <div className="pls-header-navigation-links pls-flex-1">
                    {links.map(({ title, to, external }) => (
                        external ? <a target="_blank" rel="noreferrer" onClick={() => setDrawerOpen(false)}
                                      className="pls-font-weight-600 pls-z-index-10 pls-relative"
                                      href={to}>{title}</a> :
                            <Link onClick={() => setDrawerOpen(false)} smooth
                                  className="pls-font-weight-600 pls-z-index-10 pls-relative"
                                  to={to}>{title}</Link>))}
                  </div>
                  <a onClick={() => setDrawerOpen(false)} href="https://lk.belkapost.ru" target="_blank"
                     className="pls-z-index-10">
                    <Button className="pls-button pls-button-rounded pls-button-white">
                      Личный кабинет
                    </Button>
                  </a>
                </div>
              </Drawer>
            </div>

            <h1 className="pls-header-title  pls-z-index-10 pls-relative">
              Отследить отправку
            </h1>

            <div className="pls-tracking-page-form">
              <Row className={classNames({ 'has-error': !!error })}>
                <Col xs={24} className="pls-z-index-10 pls-relative">
                  <label
                      className="pls-z-index-10 pls-relative"
                      title={'Номер отправки'}
                  >
                    Номер отправки
                  </label>
                </Col>
                <Col span={24}>
                  <Input
                      className="pls-z-index-10 pls-relative"
                      placeholder="Введите номер"
                      value={number}
                      onChange={({ target: { value } }) => setNumber(value)}
                  />
                </Col>
                {!!error && <Col xs={24} className="pls-text-red pls-fs-sm pls-z-index-10 pls-relative">
                  {error}
                </Col>}
              </Row>
              <div style={{ paddingTop: 32 }}>
                <Button
                    type="primary"
                    style={{ height: 58 }}
                    loading={loading}
                    className="pls-button pls-w-100 pls-z-index-10 pls-relative"
                    onClick={handleLoad}
                >
                  Отследить
                </Button>
              </div>
            </div>

            {/*<ErrorProvider>*/}
            {/*  <CalculationForm/>*/}
            {/*</ErrorProvider>*/}

            {/*<div className="pls-header-scroll pls-z-index-10 pls-relative">*/}
            {/*  <Icon component={Down} onClick={handleScroll}/>*/}
            {/*</div>*/}
          </div>
        </div>
        <div>
          {inited && (
              <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}
                  spinning={loading}
                  delay={500}
              >
                <div className="pls-tracking-list">
                  {notFound && (
                      <div className="pls-text-center pls-text-dark-blue pls-opacity-80">Отправление не найдено</div>
                  )}
                  <Timeline>
                    {tracking.map((_) => (
                        <Timeline.Item>
                          {renderDate(_.date)} {_.description}
                        </Timeline.Item>
                    ))}
                  </Timeline>

                </div>
              </Spin>
          )}
        </div>
        {/*<Footer/>*/}
      </div>
  );
}

export default TrackingPage;
