import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../../assets/images/belka_winter.png';
import { Button, Drawer } from 'antd';
import Down from '../../../components/icons/Down';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import CalculationForm from './CalculationForm';
import ErrorProvider from '../../../components/ErrorProvider';
import Particles from 'react-particles-js';
import { scrollToElement } from '../../../utils/scroll';
import { links } from './constants';

function Header() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleScroll = React.useCallback(
      () => {
        const calcDiv = document.getElementById('calculation');
        if (calcDiv) scrollToElement(calcDiv);
      },
      [],
  );

  const particleCount = React.useMemo(() => {
    const dpiContainer = document.getElementById('dpi');
    if (dpiContainer) {
      const width = window.outerWidth / dpiContainer.offsetWidth;
      console.debug('width', width, dpiContainer)
      return Math.ceil(width / 3);
    }
    return 25;
  }, []);

  console.debug('particleCount', particleCount);

  return (
      <div className="pls-header">
        <Particles
            className="pls-header-bg"
            params={{
              'interactivity': {
                'events': {
                  'onClick': {
                    'mode': 'push'
                  },
                  'onDiv': {
                    'selectors': '#repulse-div',
                    'mode': 'repulse'
                  },
                  'onHover': {
                    'enable': true,
                    'mode': 'repulse',
                    'parallax': {
                      'force': 60
                    }
                  }
                },
                'modes': {
                  'bubble': {
                    'distance': 600,
                    'duration': 2,
                    'opacity': 0.8,
                    'size': 40
                  },
                  'grab': {
                    'distance': 400
                  },
                  'slow': {
                    'factor': 1,
                    'radius': 0
                  }
                }
              },
              'particles': {
                'number': {
                  'density': {
                    'enable': true
                  },
                  'value': particleCount
                },
                'line_linked': {
                  'enable': false
                },
                collisions: {
                  enable: true,
                  mode: 'bounce',
                  overlap: {
                    enable: false,
                    retries: 100,
                  },
                  bounce: {
                    horizontal: {
                      random: {
                        enable: true,
                        minimumValue: 0.1,
                      },
                      value: 1,
                    },
                    vertical: {
                      random: {
                        enable: true,
                        minimumValue: 0.1,
                      },
                      value: 1,
                    },
                  }
                },
                // 'links': {
                //   'color': {
                //     'value': '#ffffff'
                //   },
                //   'distance': 150,
                //   'enable': true,
                //   'opacity': 0.4,
                //   'shadow': {
                //     'color': {
                //       'value': 'lime'
                //     }
                //   }
                // },
                'move': {
                  'speed': 0.3,
                  outModes: {
                    default: 'bounce',
                    bottom: 'bounce',
                    left: 'bounce',
                    right: 'bounce',
                    top: 'bounce'
                  },
                  random: true,
                },
                'shape': {
                  'type': [
                    'image',
                  ],
                  'image': [
                    {
                      'src': '/images/bg1.svg',
                      'height': 195,
                      'width': 157
                    },
                    {
                      'src': '/images/bg2.svg',
                      'height': 242,
                      'width': 193
                    },
                    {
                      'src': '/images/bg3.svg',
                      'height': 197,
                      'width': 159
                    },
                    {
                      'src': '/images/bg4.svg',
                      'height': 244,
                      'width': 196
                    },
                    {
                      'src': '/images/bg5.svg',
                      'height': 212,
                      'width': 264
                    },
                    {
                      'src': '/images/bg6.svg',
                      'height': 222,
                      'width': 176
                    }
                  ]
                },
                'color': {
                  'value': '#CCC'
                },
                'opacity': {
                  'value': {
                    'min': 0.1,
                    'max': 0.5
                  },
                  'animation': {
                    'enable': true,
                    'speed': 0.3,
                    'minimumValue': 0.1
                  }
                },
                'size': {
                  'value': 60,
                  'random': false,
                  // 'anim': {
                  //   'enable': true,
                  //   'speed': 4,
                  //   'size_min': 10,
                  //   'sync': false
                  // }
                }
              },
              'retina_detect': false
            }}
        />
        <div className="pls-header-content">
          <div className="pls-header-navigation">
            <img src={logo} alt="logo" width={184} className="pls-header-logo pls-z-index-10"/>
            <div className="pls-header-navigation-links">
              {links.map(({ title, to, external }) => (
                  external ? <a target="_blank" rel="noreferrer" className="pls-text-white pls-font-weight-600 pls-z-index-10 pls-relative" href={to}>{title}</a> :
                  <Link smooth className="pls-text-white pls-font-weight-600 pls-z-index-10 pls-relative"
                        to={to}>{title}</Link>))}
            </div>
            <div className="pls-z-index-10">
              <a href="tel:+74951210799" className="pls-mr-4 pls-text-white pls-fs-sm">+7 (495) 121-07-99</a>
              <a href="https://lk.belkapost.ru" target="_blank">
                <Button className="pls-button pls-button-rounded pls-button-white">
                  Личный кабинет
                </Button>
              </a>
            </div>

            {/*<Button className="pls-z-index-10" onClick={() => setDrawerOpen(!drawerOpen)}>*/}
            {drawerOpen ? <MenuFoldOutlined className="pls-z-index-10 pls-d-lg-none" onClick={() => setDrawerOpen(!drawerOpen)}/> :
                <MenuUnfoldOutlined onClick={() => setDrawerOpen(!drawerOpen)} className="pls-z-index-10 pls-d-lg-none"/>}
            {/*</Button>*/}

            <Drawer className="pls-header-drawer" closable={false} onClose={() => setDrawerOpen(false)}
                    visible={drawerOpen}>
              <div className="pls-d-flex pls-flex-column pls-h-100">
                <div className="pls-header-navigation-links pls-flex-1">
                  {links.map(({ title, to, external }) => (
                      external ? <a target="_blank" rel="noreferrer" onClick={() => setDrawerOpen(false)} className="pls-font-weight-600 pls-z-index-10 pls-relative" href={to}>{title}</a> :
                      <Link onClick={() => setDrawerOpen(false)} smooth className="pls-font-weight-600 pls-z-index-10 pls-relative"
                            to={to}>{title}</Link>))}
                </div>
                <a onClick={() => setDrawerOpen(false)} href="https://lk.belkapost.ru" target="_blank" className="pls-z-index-10">
                  <Button className="pls-button pls-button-rounded pls-button-white">
                    Личный кабинет
                  </Button>
                </a>
              </div>
            </Drawer>
          </div>

          <h1 className="pls-header-title  pls-z-index-10 pls-relative">
            Агрегатор служб доставки для Бизнеса
          </h1>

          <ErrorProvider>
            <CalculationForm/>
          </ErrorProvider>

          <div className="pls-header-subtitle  pls-z-index-10 pls-relative">
            Мы собрали все ведущие службы доставки
            <br/>
            в едином сервисе и добились самых низких цен.
            <br/>
            Доставка по всему Миру курьером, почтой или
            <br/>
            в пункты выдачи заказов.
          </div>

          <div className="pls-header-scroll pls-z-index-10 pls-relative">
            <Icon component={Down} onClick={handleScroll}/>
          </div>
        </div>
      </div>
  );
}

export default Header;
