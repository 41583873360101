import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../../assets/images/belka_winter.png';
import { links } from './constants';

function Footer() {
  return (
      <div className="pls-footer pls-text-center">
        <img src={logo} alt="logo" width={184} className="pls-footer-logo"/>
        <div className="pls-footer-links">
          {links.map(({ title, to, external }) => external ?
              <a target="_blank" rel="noreferrer" className="pls-footer-link" href={to}>{title}</a> :
              <Link smooth to={to} className="pls-footer-link">{title}</Link>)}
        </div>
        <div className="pls-footer-capture pls-text-grey pls-opacity-80">
          © 2021 All rights reserved
        </div>
      </div>
  );
}

export default Footer;
